<template>
   <div>
      <div class="md-layout">
         <div class="md-layout-item md-size-100">
            <div class="text-right">
               <md-button class="md-round md-info" target="_blank" href="https://api.herreriaosmar.com/api/material/requerido">
                  <md-icon>article</md-icon>Reporte requeridos
               </md-button>
            </div>
            <md-card>
               <md-card-header class="md-card-header-icon md-card-header-green">
                  <div class="card-icon">
                     <md-icon>build</md-icon>
                  </div>
                  <h4 class="title">Material</h4>
               </md-card-header>
               <md-card-content>
                  <md-table v-model="material">
                     <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <!-- <md-table-cell md-label="Numero de material">{{ item.id }}</md-table-cell> -->
                        <md-table-cell md-label="Name">
                           {{ item.nombre}}
                        </md-table-cell>
                        <md-table-cell md-label=" Stock">
                           {{ item.stock}}
                        </md-table-cell>
                        <md-table-cell md-label="Observaciones">
                           {{ item.observaciones}}
                        </md-table-cell>
                        <md-table-cell md-label="Acciones" :class="getAlignClasses(item)">
                           <md-button class="md-just-icon md-warning md-round" @click.native="edit(item)">
                              <md-icon>edit</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-success md-round" @click.native="add(item)">
                              <md-icon>add</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-danger md-round" @click.native="remove(item)">
                              <md-icon>remove</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-info md-round" @click.native="note(item)">
                              <md-icon>edit_note</md-icon>
                           </md-button>
                        </md-table-cell>
                     </md-table-row>
                  </md-table>
               </md-card-content>
            </md-card>
         </div>
      </div>
      <md-dialog :md-active.sync="modalmaterial">
         <md-dialog-title>Editar material</md-dialog-title>
         <MaterialForm :material_edit="material_edit" v-on:success="reload"/>
      </md-dialog>
      <md-dialog :md-active.sync="modalnotas">
         <md-dialog-title>Observaciones</md-dialog-title>
         <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
               <md-card>
                  <md-card-header class="md-card-header-icon md-card-header-green">
                     <div class="card-icon">
                        <md-icon>note_add</md-icon>
                     </div>
                  </md-card-header>

                  <md-card-content>
                     <div class="md-layout">
                        <div class="md-layout-item md-small-size-100">
                           <md-field>
                              <label>Observaciones</label>
                              <md-input v-model="nota_edit" type="text"></md-input>
                           </md-field>
                        </div>
                     </div>
                  </md-card-content>

                  <md-card-actions md-alignment="right">
                     <md-button class="md-success" @click="saveNote">
                        <md-icon>send</md-icon>Guardar
                     </md-button>
                  </md-card-actions>
               </md-card>
            </div>
         </div>
      </md-dialog>
   </div>
</template>
<script>
import MaterialForm from '../../components/forms/MaterialForm.vue';
const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
   name: 'inventario_list',
   components: {
      MaterialForm
   },
   mixins: [],
   props: {

   },
   data() {
      return {
         material: [],
         modalmaterial: false,
         modalnotas: false,
         nota_edit: '',
         id_edit: 0,
      }
   },
   computed: {

   },
   watch: {

   },
   mounted() {
      this.initialze();
   },
   methods: {
      initialze() {
         axios.get('https://api.herreriaosmar.com/api/material'
         ).then(response => {
            this.material = response.data;
         }).catch(error => {

         });
      },
      edit(item) {
         this.material_edit = item;
         this.modalmaterial = true;
      },
      reload(){
         this.modalmaterial = false;
         this.initialze();
      },
      add(item) {
         let stock = item.stock + 1;
         axios.post('https://api.herreriaosmar.com/api/material/stock/' + item.id, {
            stock: stock,
         }).then(response => {
            this.$notify({
               timeout: 2500,
               message:
                  "Stock agregado con exito",
               icon: "edit",
               horizontalAlign: 'center',
               verticalAlign: 'top',
               type: 'success',
            });
            this.initialze();
         }).catch(error => {
            this.$notify({
               timeout: 2500,
               message:
                  "No se pudo agregar stock, vuelve a intentar",
               icon: "error",
               horizontalAlign: 'center',
               verticalAlign: 'top',
               type: 'danger',
            });
         });
      },
      note(item) {
         this.modalnotas = true;
         this.nota_edit = item.observaciones;
         this.id_edit = item.id;
      },
      saveNote(){
         axios.post('https://api.herreriaosmar.com/api/material/nota/' + this.id_edit, {
               nota: this.nota_edit,
            }).then(response => {
               this.$notify({
                  timeout: 2500,
                  message:
                     "Nota agregado con exito",
                  icon: "edit",
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'success',
               });
               this.initialze();
               this.modalnotas = false;
            }).catch(error => {
               this.$notify({
                  timeout: 2500,
                  message:
                     "No se pudo agregar nota, vuelve a intentar",
                  icon: "error",
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger',
               });
            });
      },
      remove(item) {
         if (item.stock > 0) {
            let stock = item.stock - 1;
            axios.post('https://api.herreriaosmar.com/api/material/stock/' + item.id, {
               stock: stock,
            }).then(response => {
               this.$notify({
                  timeout: 2500,
                  message:
                     "Stock agregado con exito",
                  icon: "edit",
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'success',
               });
               this.initialze();
            }).catch(error => {
               this.$notify({
                  timeout: 2500,
                  message:
                     "No se pudo agregar stock, vuelve a intentar",
                  icon: "error",
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger',
               });
            });
         } else {
            this.$notify({
               timeout: 2500,
               message:
                  "No se puede remover stock menor a 1",
               icon: "error",
               horizontalAlign: 'center',
               verticalAlign: 'top',
               type: 'danger',
            });
         }
      },
      getAlignClasses: ({ id }) => ({
         "text-right": id,
      }),
   }
};
</script>
<style lang='' scoped>
</style>