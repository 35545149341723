<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">Lista de trabajo</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="piezas">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Numero de pieza">{{ item.pieza.id }}</md-table-cell>
                <md-table-cell md-label="Diseño">{{ item.pieza.id }}</md-table-cell>
                <md-table-cell md-label="Pieza">{{ item.pieza.nombre }}</md-table-cell>
                <md-table-cell md-label="Observaciones">{{ item.pieza.observaciones }}</md-table-cell>
                <md-table-cell md-label="Medidas">
                  <div v-for="(med, i) in item.pieza.medidas" :key="i">
                    <p>{{ i }} : {{ med }}</p>
                  </div>
                </md-table-cell>
                <md-table-cell md-label="Asignado">{{ item.fecha }}</md-table-cell>
                <md-table-cell md-label="Limite">{{ item.pieza.contrato.limite }}</md-table-cell>
                <md-table-cell md-label="Actions" :class="getAlignClasses(item)">
                  <md-button class="md-just-icon md-info md-round" @click.native="finalizar(item.pieza.id)">
                    <md-icon>check_circle</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import Swal from "sweetalert2";
export default {
  name: 'WorkList',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {
      piezas: [],
      id: 0,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.id = localStorage.getItem('id');
    axios.get('https://api.herreriaosmar.com/api/asignaciones/' + this.id
    ).then(response => {
      this.piezas = response.data;
    }).catch(error => {

    });
  },
  methods: {
    finalizar(id) {
      Swal.fire({
        title: "Estas seguro que quieres marcar como finalizada",
        text: `No podras revertir esto y sera revisado por el gerente`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.post('https://api.herreriaosmar.com/api/pieza/' + id
          ).then(response => {
            this.$notify({
              timeout: 2500,
              message:
                "Pieza finalizada con exito",
              icon: "check_circle",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
            });
          }).catch(error => {
            this.$notify({
              timeout: 2500,
              message:
                "No se ha podido finalizar la pieza, vuelva a intentar",
              icon: "error",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger',
            });
          });
        }
      });

    },
    getAlignClasses: ({ id }) => ({
      "text-right": id,
    }),
  }
};
</script>
<style lang='' scoped>
</style>