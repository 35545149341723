<template>
  <div class="user">
    <div class="photo">
      <img :src="'http://ddragon.leagueoflegends.com/cdn/11.15.1/img/profileicon/'+avatar+'.png'" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a @click="logout">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Cerrar sesion</span>
                </a>
              </li>
              <li>
                <a @click="lock">
                  <span class="sidebar-mini">B</span>
                  <span class="sidebar-normal">Bloquear</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: "Tania Andrew",
    },
    rtlTitle: {
      type: String,
      default: "تانيا أندرو",
    },
    avatar: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    logout(){
      localStorage.removeItem('name');
      localStorage.removeItem('token');
      location.reload();
    },
    lock(){
      localStorage.removeItem('token');
      location.reload();
    }
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
