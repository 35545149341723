<template>
  <div>
    <ClientesForm v-on:cliente_id="nuevoCliente"/>
  </div>
</template>
<script>
import ClientesForm from "@/components/forms/ClientesForm.vue";
export default {
  name: 'Clientes_form',
  components: {
    ClientesForm,
  },
  mixins: [],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    nuevoCliente(value){
      window.location.href = '/#/clientes/list';
    }
  }
};
</script>
<style lang='' scoped>
</style>