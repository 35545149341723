import { render, staticRenderFns } from "./AsignacionForm.vue?vue&type=template&id=14b3a919&scoped=true&"
import script from "./AsignacionForm.vue?vue&type=script&lang=js&"
export * from "./AsignacionForm.vue?vue&type=script&lang=js&"
import style0 from "./AsignacionForm.vue?vue&type=style&index=0&id=14b3a919&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b3a919",
  null
  
)

export default component.exports