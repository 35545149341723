<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <i class="fas fa-file-signature"></i>
          </div>
          <p class="category">Contratos firmados</p>
          <h3 class="title">
            {{count_contratos}}
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Esta semana
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>approval</md-icon>
          </div>
          <p class="category">Contratos entregados</p>
          <h3 class="title">
            {{count_contratos_finalizados}}
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Esta semana
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>account_balance</md-icon>
          </div>
          <p class="category">Ganancia Mensual</p>
          <h3 class="title">
            $ {{ganancias}}
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            <!-- Ultimos <animated-number :value="30"></animated-number> Dias -->
            Este Mes
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>account_balance</md-icon>
          </div>
          <p class="category">Gasto mensual</p>
          <h3 class="title">
            $ {{gastos}}
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            <!-- Ultimos <animated-number :value="30"></animated-number> Dias -->
            Este Mes
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card header-animation="true" :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        :chart-responsive-options="emailsSubscriptionChart.responsiveOptions" chart-type="Bar" chart-inside-header
        background-color="rose">
        <md-icon slot="fixed-button">build</md-icon>
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">Contratos mensuales</h4>
          <!-- <p class="category">Last Campaign Performance</p> -->
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="10"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card :chart-data="dailySalesChart.data" :chart-options="dailySalesChart.options" chart-type="Line"
        chart-inside-header background-color="green">
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">Ventas Mensuales</h4>
          <!-- <p class="category">
            <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i>
              <animated-number :value="55"></animated-number>%
            </span>
            Incremento en las ventas de hoy.
          </p> -->
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            Actualizado hace <animated-number :value="4"></animated-number> minutos
          </div>
        </template>
      </chart-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card :chart-data="dataCompletedTasksChart.data" :chart-options="dataCompletedTasksChart.options"
        chart-type="Line" chart-inside-header background-color="blue">
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">Contratos entregados</h4>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            Actualizado hace
            <animated-number :value="26"></animated-number> minutos
          </div>
        </template>
      </chart-card>
    </div>
    <!-- <div style="width: 100%;">
      <calendar/>
    </div> -->

  </div>
</template>

<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import {
  StatsCard,
  ChartCard,
  AnimatedNumber,
} from "@/components";
// import Calendar from "./Calendar.vue";

export default {
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
    // Calendar
  },
  data() {
    return {
      ganancias: 0,
      count_contratos: 0,
      gastos: 0,
      count_contratos_finalizados: 0,
      product1: "./img/card-2.jpg",
      product2: "./img/card-3.jpg",
      product3: "./img/card-1.jpg",
      seq2: 0,
      componentKey: 0,
      dailySalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 30, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: [],
          series: [[]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 15, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [],
          series: [
            [],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 15,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  methods: {
    graficaVentas(item, index) {
      this.dailySalesChart.data.labels.push(item.mes);
      this.emailsSubscriptionChart.data.labels.push(item.mes);
      this.dataCompletedTasksChart.data.labels.push(item.mes);
      this.dailySalesChart.data.series[0].push(item.monto);
      this.emailsSubscriptionChart.data.series[0].push(item.contratos);
      if (this.dailySalesChart.options.high < item.monto) {
        this.dailySalesChart.options.high = item.monto + 1000;
      }
    },
    initialize() {
      this.dailySalesChart.data.labels = [];
      this.emailsSubscriptionChart.data.labels = [];
      this.dataCompletedTasksChart.data.labels = [];
      this.dailySalesChart.data.series[0] = [];
      this.emailsSubscriptionChart.data.series[0] = [];
      axios.get('https://api.herreriaosmar.com/api/dashboard'
      ).then(response => {
        response.data.ventas.forEach(this.graficaVentas);
        this.count_contratos = response.data.contratos;
        this.ganancias = response.data.ganancias;
        this.gastos = response.data.gastos;
        this.count_contratos_finalizados = response.data.entregados;
      }).catch(error => {

      });
      // chart.update();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
