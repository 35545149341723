<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="blue"> <!-- ToDo: Aplicar Color #001A3E -->
        <h4 slot="title" class="title">Inicio de Sesion</h4>
        <p slot="description" class="description">Usuarios</p>
        <div slot="description">
          <span v-if="hasMessages" class="text-danger">{{message}}</span>
        </div>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Correo...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <form v-csrf-token></form>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Contraseña...</label>
          <md-input v-model="password" type="password"></md-input>
          <span class="md-error">{{message}}</span>
        </md-field>
        <md-button slot="footer" class="md-simple md-success md-lg" @click="send">
          Iniciar Sesion
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import { LoginCard } from "@/components";
export default {
  components: {
    LoginCard,
  }, name: '',
  mixins: [],
  props: {

  },
  data() {
    return {
      email: null,
      password: null,
      name: '',
      hasMessages: false,
      messege: '',
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.name = localStorage.getItem('name');
    if(this.name != '' && this.name != undefined){
      window.location.href = '/#/dashboard';
    }
  },
  methods: {
    send() {
      this.hasMessages = false;
      axios.post('https://api.herreriaosmar.com/api/login', {
        email: this.email,
        password: this.password
      }
      ).then(response => {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('rol', response.data.data.rol);
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('name',response.data.data.name);
        localStorage.setItem('id',response.data.data.id);
        window.location.href = '/#/dashboard';
      }).catch(error => {
        this.hasMessages = true;
        this.message = 'Usuario y contraseña no coinciden';
        // swal.fire(error.message)
      });

    }
  }
};
</script>

<style>
</style>
