<template>
  <div class="form-content">
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>badge</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100">
                <md-table v-model="piezas">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Pieza">
                      {{ item.nombre }} <br>
                      <small>{{item.observaciones}}</small>
                    </md-table-cell>
                    <md-table-cell md-label="Medidas">
                      <div v-for="(med, i) in item.medidas" :key="i">
                        <p>{{ i }} : {{ med }}</p>
                      </div>
                    </md-table-cell>
                    <md-table-cell md-label="Medidas">
                      <md-field>
                        <label for="empleado">Empleado</label>
                        <md-select v-model="item.herrero" name="empleado" id="empleado">
                          <md-option value="0" class="md-disabled" disabled>Selecciona empleado</md-option>
                          <md-option :value="empleado.id" v-for="empleado in empleados" :key="empleado.id">
                            {{empleado.usuario.name}}
                          </md-option>
                        </md-select>
                      </md-field>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <div class="text-right">
          <md-button class="md-round md-success" @click="save()">
            <md-icon>send</md-icon>Guardar
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { integer } from 'vee-validate/dist/rules';

const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
  name: 'asignacion_form',
  components: {
  },
  mixins: [],
  props: {
    contrato_id: integer,
  },
  data() {
    return {
      piezas: [],
      empleados: [],
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    axios.get('https://api.herreriaosmar.com/api/asignacion/empleado/' + this.contrato_id
    ).then(response => {
      response.data.piezas;
      this.empleados = response.data.empleados;
      response.data.piezas.forEach(this.initPiezas);
    }).catch(error => {

    });
  },
  methods: {
    initPiezas(item, index) {
      this.piezas.push({
        id: item.id,
        nombre: item.nombre,
        medidas: item.medidas,
        herrero: (item.asignacion ? item.asignacion.empleado_id : 0),
        observaciones: item.observaciones,
        material: 0,
        cantidad: 0,
      });
    },
    save() {
      axios.post('https://api.herreriaosmar.com/api/asignacion/empleado', {
        piezas: this.piezas,
      }).then(response => {
        this.$notify({
          timeout: 2500,
          message:
            "Piezas asignadas correctamente",
          icon: "archive",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
        });
        this.$emit('success');
      }).catch(error => {
        this.$notify({
          timeout: 2500,
          message:
            "No se pudo realizar la asignacion, vuelve a intentar",
          icon: "error",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.form-content {
  overflow-y: auto;
}
</style>