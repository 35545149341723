<template>
   <div>
      <div class="md-layout">
         <div class="md-layout-item md-size-100">
            <md-card>
               <md-card-header class="md-card-header-icon md-card-header-green">
                  <div class="card-icon">
                     <md-icon>person</md-icon>
                  </div>
                  <h4 class="title">Clientes</h4>
               </md-card-header>
               <md-card-content>
                  <md-table v-model="empleados">
                     <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Numero de empleado">{{ item.id }}</md-table-cell>
                        <md-table-cell md-label="Status">
                           <div v-if="item.active" class="text-success">Activo</div>
                           <div v-else class="text-danger">Inactivo</div>
                        </md-table-cell>
                        <md-table-cell md-label="Empleado">
                           {{ item.name }}
                        </md-table-cell>
                        <md-table-cell md-label="Rol">
                           {{ item.tipo }}
                        </md-table-cell>
                        <md-table-cell md-label="Acciones" :class="getAlignClasses(item)">
                           <md-button class="md-just-icon md-primary md-round" @click.native="password_reset(item)">
                              <md-icon>lock_reset</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-warning md-round" @click.native="edit(item)">
                              <md-icon>edit</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-danger md-round" @click.native="remove(item)"
                              v-if="item.active">
                              <md-icon>person_remove</md-icon>
                           </md-button>
                           <md-button class="md-just-icon md-info md-round" @click.native="reactive(item)" v-else>
                              <md-icon>restart_alt</md-icon>
                           </md-button>
                        </md-table-cell>
                     </md-table-row>
                  </md-table>
               </md-card-content>
            </md-card>
         </div>
      </div>
      <md-dialog :md-active.sync="modalempleado">
         <md-dialog-title>Editar Empleado</md-dialog-title>
         <EmpleadoForm :empleado_edit="empleado_edit" v-on:success="edited" />
      </md-dialog>
   </div>
</template>
<script>
import Swal from "sweetalert2";
import EmpleadoForm from '../../components/forms/EmpleadoForm.vue';
const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
   name: 'eampleado_list',
   components: {
      EmpleadoForm
   },
   mixins: [],
   props: {

   },
   data() {
      return {
         empleados: [],
         modalempleado: false,
         contraseña: null,
         empleado_edit: null,
      }
   },
   computed: {

   },
   watch: {

   },
   mounted() {
      this.initialize();
   },
   methods: {
      initialize() {
         axios.get('https://api.herreriaosmar.com/api/empleados'
         ).then(response => {
            this.empleados = response.data;
         }).catch(error => {

         });
      },
      edit(item) {
         this.empleado_edit = item;
         this.modalempleado = true;
      },
      edited() {
         this.modalempleado = false;
         this.initialize();
      },
      getAlignClasses: ({ id }) => ({
         "text-right": id,
      }),
      reactive(item) {
         Swal.fire({
            title: "¿Estás seguro?",
            text: "Se reactivara el usuario",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Si",
            cancelButtonText: "No",
            buttonsStyling: false,
         }).then((result) => {
            if (result.value) {
               axios.post('https://api.herreriaosmar.com/api/empleado/restablecer/' + item.id
               ).then(response => {
                  this.$notify({
                     timeout: 2500,
                     message:
                        "Empleado restablecido con éxito",
                     icon: "person_remove",
                     horizontalAlign: 'center',
                     verticalAlign: 'top',
                     type: 'success',
                  });
                  this.initialize()
               }).catch(error => {
                  this.$notify({
                     timeout: 2500,
                     message:
                        "No se pudo restablecer empleado",
                     icon: "error",
                     horizontalAlign: 'center',
                     verticalAlign: 'top',
                     type: 'danger',
                  });
               });
            }
         });
      },
      remove(item) {
         Swal.fire({
            title: "¿Estás seguro?",
            text: "El usuario ya no podrá acceder",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Si",
            cancelButtonText: "No",
            buttonsStyling: false,
         }).then((result) => {
            if (result.value) {
               axios.delete('https://api.herreriaosmar.com/api/empleado/' + item.id
               ).then(response => {
                  this.$notify({
                     timeout: 2500,
                     message:
                        "Empleado removido con éxito",
                     icon: "person_remove",
                     horizontalAlign: 'center',
                     verticalAlign: 'top',
                     type: 'success',
                  });
                  this.initialize()
               }).catch(error => {
                  this.$notify({
                     timeout: 2500,
                     message:
                        "No se pudo remover empleado",
                     icon: "error",
                     horizontalAlign: 'center',
                     verticalAlign: 'top',
                     type: 'danger',
                  });
               });
            }
         });

      },
      password_reset(item) {
         axios.post('https://api.herreriaosmar.com/api/empleado/password/reset/' + item.id
         ).then(response => {
            this.$notify({
               timeout: 0,
               message:
                  "Conraseña reiniciada con exito, nueva contraseña: " + response.data,
               icon: "archive",
               horizontalAlign: 'center',
               verticalAlign: 'top',
               type: 'success',
            });
         }).catch(error => {
            this.$notify({
               timeout: 2500,
               message:
                  "No se pudo reiniciar la contraseña, vuelve a intentar",
               icon: "error",
               horizontalAlign: 'center',
               verticalAlign: 'top',
               type: 'danger',
            });
         });
      }
   }
};
</script>
<style lang='' scoped>
</style>