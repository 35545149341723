<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img class="img" slot="imageProfile" :src="'http://ddragon.leagueoflegends.com/cdn/11.15.1/img/profileicon/'+avatar+'.png'" />
        <h4 slot="title" class="title">{{name}}</h4>
        <md-field slot="content">
          <label>Enter Password</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>
        <md-button href class="md-success md-round" slot="footer" @click="send">Desbloquear</md-button>
      </lock-card>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import { LockCard } from "@/components";
export default {
  components: {
    LockCard,
  },
  data() {
    return {
      password: null,
      image: "./img/faces/avatar.jpg",
      name: "",
      email: "",
      avatar: 2,
    };
  },
  mounted() {
    this.name = localStorage.getItem('name');
    this.email = localStorage.getItem('email');
    if(this.token != '' && this.token != undefined){
      window.location.href = '/#/dashboard';
    }
  },
  methods: {
    send() {
      console.log('hola');
      this.hasMessages = false;
      axios.post('https://api.herreriaosmar.com/api/login', {
        email: this.email,
        password: this.password
      }
      ).then(response => {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('rol', response.data.data.tipo);
        localStorage.setItem('name', response.data.data.name);
        window.location.href = '/#/dashboard';
      }).catch(error => {
        this.hasMessages = true;
        this.message = 'Usuario y contraseña no coinciden';
        // swal.fire(error.message)
      });

    }
  }
};
</script>
<style>

</style>
