var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("inventory_2")])],1)]),_c('md-card-content',[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Nombre")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.material.nombre),callback:function ($$v) {_vm.$set(_vm.material, "nombre", $$v)},expression:"material.nombre"}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"text-danger"},[_vm._v("Ingrese Nombre de material valido")])]}}])})],1),_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"noExt","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Stock")]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.material.stock),callback:function ($$v) {_vm.$set(_vm.material, "stock", $$v)},expression:"material.stock"}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"text-danger"},[_vm._v("Ingresa stock valido")])]}}])})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"sueldo","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Observaciones")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.material.observaciones),callback:function ($$v) {_vm.$set(_vm.material, "observaciones", $$v)},expression:"material.observaciones"}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"text-danger"},[_vm._v("Ingresa observaciones valido")])]}}])})],1)])])],1),_c('md-card-actions',{attrs:{"md-alignment":"right"}},[_c('md-button',{staticClass:"md-success",on:{"click":_vm.save}},[_c('md-icon',[_vm._v("send")]),_vm._v("Guardar ")],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }