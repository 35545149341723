<template>
    <div class="format-content">
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <!-- {{activecolor ? activecolor : 'green' }} -->
                        <div class="card-icon">
                            <md-icon>today</md-icon>
                        </div>
                        <h4 class="title">Desde</h4>
                    </md-card-header>

                    <md-card-content>
                        <md-datepicker v-model="desde">
                            <label>Selecciona Fecha</label>
                        </md-datepicker>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <!-- {{activecolor ? activecolor : 'green' }} -->
                        <div class="card-icon">
                            <md-icon>today</md-icon>
                        </div>
                        <h4 class="title">Hasta</h4>
                    </md-card-header>

                    <md-card-content>
                        <md-datepicker v-model="hasta">
                            <label>Selecciona Fecha</label>
                        </md-datepicker>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>tune</md-icon>
                        </div>
                        <h4 class="title">Concepto</h4>
                    </md-card-header>

                    <md-card-content>
                        <div class="md-layout-item">
                            <md-field>
                                <label>Concepto..</label>
                                <md-input v-model="concepto"></md-input>
                            </md-field>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <div class="text-right">
                    <md-button class="md-round md-info" @click.native="buscar">
                        <md-icon>search</md-icon> Buscar
                    </md-button>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>paid</md-icon>
                        </div>
                        <h4 class="title">Movimiento</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="transacciones">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Id">{{ item.id }}</md-table-cell>
                                <md-table-cell md-label="Concepto">{{ item.concepto }}</md-table-cell>
                                <md-table-cell md-label="Autorizo">{{ item.usuario.name }}</md-table-cell>
                                <md-table-cell md-label="Realizado">{{ item.fecha }}</md-table-cell>
                                <md-table-cell md-label="Monto" class="td-number">$ {{ item.cantidad }}</md-table-cell>
                                <md-table-cell md-label="Remove">
                                    <md-button class="md-just-icon md-round md-simple" @click.native="remove(item)">
                                        <md-icon>close</md-icon>
                                    </md-button>
                                </md-table-cell>
                                <!-- <md-table-cell md-label="Actions" :class="getAlignClasses(item)">
                                    <md-button class="md-just-icon md-info md-round"
                                        @click.native="modaldescarga = true">
                                        <md-icon>file_download</md-icon>
                                    </md-button>
                                    <md-button class="md-just-icon md-warning md-round" @click.native="edit(item)">
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button class="md-just-icon md-success md-round"
                                        @click.native="asignar_material(item.id)">
                                        <md-icon>build</md-icon>
                                    </md-button>
                                    <md-button class="md-just-icon md-primary md-round"
                                        @click.native="asignar_empleado(item.id)">
                                        <md-icon>badge</md-icon>
                                    </md-button>
                                </md-table-cell> -->
                            </md-table-row>
                        </md-table>
                        <div class="table table-stats">
                            <div class="td-price">
                                <div class="td-total">Total</div>
                                <span>
                                    <small>$</small>
                                    {{ balance }}
                                </span>
                            </div>
                            <div class="text-right">
                                <md-button class="md-info md-round" @click="add()">
                                    Agregar Movimiento
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="text-right">
                            <md-button class="md-warning md-round" @click="salarios()">
                                <md-icon>paid</md-icon>
                                Procesar Salarios
                            </md-button>
                            <md-button class="md-warning md-round" @click="add()">
                                <md-icon>download</md-icon>
                                Descargar Reporte
                            </md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-dialog :md-active.sync="activemodal">
            <md-dialog-title>Nuevo movimiento</md-dialog-title>
            <TransaccionesForm v-on:created="created" />
        </md-dialog>
    </div>
</template>

<script>
import TransaccionesForm from "../../components/forms/TransaccionesForm.vue";
import Swal from "sweetalert2";

const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
    name: 'TransaccionesTable',
    components: {
        TransaccionesForm
    },
    mixins: [],
    props: {

    },
    data() {
        return {
            transacciones: [],
            balance: 0,
            desde: null,
            hasta: null,
            concepto: null,
            activemodal: false,
        }
    },
    computed: {

    },
    watch: {
        transacciones: {
            handler(transacciones) {
                transacciones.forEach(element => {
                    this.balance += element.cantidad;
                });
            },
            deep: true
        }
    },
    mounted() {
        this.buscar();
    },
    methods: {
        created() {
            this.buscar();
            this.activemodal = false;
        },
        buscar() {
            this.balance = 0;
            axios.get('https://api.herreriaosmar.com/api/transacciones', {
                params: {
                    desde: this.desde,
                    hasta: this.hasta,
                    concepto: this.concepto,
                }
            }).then(response => {
                this.transacciones = response.data;
            }).catch(error => {

            });
        },
        getAlignClasses: ({ id }) => ({
            "text-right": id,
        }),
        add() {
            this.activemodal = true;
        },
        salarios() {
            axios.post('https://api.herreriaosmar.com/api/transaccion/salarios'
            ).then(response => {
                if (response.data != '') {
                    Swal.fire({
                        title: "Salarios no procesados",
                        text: response.data,
                        icon: "error",
                        type: "error",
                        confirmButtonClass: "md-button md-info",
                        buttonsStyling: false,
                    });
                } else {
                    this.$notify({
                        timeout: 2500,
                        message:
                            "Salarios semanales procesados",
                        icon: "person_remove",
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    this.buscar();
                }
            }).catch(error => {
                this.$notify({
                    timeout: 2500,
                    message:
                        "No se pudo procesar salarios",
                    icon: "error",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            });
        },
        remove(item) {
            Swal.fire({
                title: "¿Estás seguro?",
                text: `No podras deshacer esto`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "md-button md-success",
                cancelButtonClass: "md-button md-danger",
                confirmButtonText: "Si",
                cancelButtonText: "No",
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    axios.delete('https://api.herreriaosmar.com/api/transaccion/' + item.id
                    ).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Movimiento eliminado con éxito",
                            icon: "person_remove",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.buscar();
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo remover Movimiento",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                }
            });

        },
    }
};
</script>

<style lang="scss" scoped>
.form-content {
    overflow-y: auto;
}

.text-right .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
}

.md-table .md-table-head:last-child {
    text-align: center;
}

.iva {
    width: 30%;
}

.table-stats {
    display: flex;
    align-items: center;
    text-align: right;
    flex-flow: row wrap;

    .td-price .td-total {
        display: inline-flex;
        font-weight: 500;
        font-size: 1.0625rem;
        margin-right: 50px;
    }

    &.table-striped .td-price {
        border-bottom: 0;
    }

    .td-price {
        font-size: 26px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    .td-price,
    >div {
        flex: 0 0 100%;
        padding: 12px 8px;
    }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
    text-align: center;
}
</style>