<template>
  <div class="wrapper" :class="[
    { 'nav-open': $sidebar.showSidebar },
    { rtl: $route.meta.rtlActive },
  ]">
    <notifications></notifications>
    <side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor" title="HQA"
      logo="https://st4.depositphotos.com/17903764/21485/v/600/depositphotos_214859954-stock-illustration-initial-letter-pg-logo-template.jpg">
      <user-menu :title="name" :avatar="3"></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">

        <sidebar-item :link="{ name: 'Inicio', icon: 'dashboard', path: '/dashboard' }" v-if="this.rol != 'Herrero'">
        </sidebar-item>

        <sidebar-item :link="{ name: 'Contratos', icon: 'article' }" v-if="this.rol != 'Herrero'">
          <sidebar-item :link="{ name: 'Nuevos', path: '/contratos/nuevos' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Listado', path: '/contratos/activos' }"></sidebar-item>
        </sidebar-item>
        <!-- <sidebar-item :link="{ name: 'Finanzas', icon: 'payments' }">
          <sidebar-item :link="{ name: 'Entradas', path: '/table-list/regular' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Gastos', path: '/table-list/regular' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Reportes', path: '/table-list/regular' }"></sidebar-item>
        </sidebar-item> -->
        <sidebar-item :link="{ name: 'Transacciones', icon: 'paid', path: '/transacciones' }"
          v-if="this.rol == 'Administrador'"></sidebar-item>

        <sidebar-item :link="{ name: 'Inventarios', icon: 'inventory', path: '/inventarios' }"
          v-if="this.rol != 'Herrero'">
          <sidebar-item :link="{ name: 'Nuevo', path: '/inventario/nuevo' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Listado', path: '/inventario/list' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: 'Empleados', icon: 'badge' }" v-if="this.rol != 'Herrero'">
          <sidebar-item :link="{ name: 'Nuevo', path: '/empleados/nuevo' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Listado', path: '/empleados/list' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: 'Clientes', icon: 'account_circle' }" v-if="this.rol != 'Herrero'">
          <sidebar-item :link="{ name: 'Nuevo', path: '/clientes/nuevos' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Listado', path: '/clientes/list' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: 'Lista de trabajo', icon: 'checklist', path: '/worklist' }"
          v-if="this.rol == 'Herrero'"></sidebar-item>
          
        <sidebar-item :link="{ name: 'Lista de trabajo', icon: 'checklist' }" v-if="this.rol == 'Gerente'">
          <sidebar-item :link="{ name: 'Lista de trabajo', path: '/worklist' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Lista general', path: '/worklist/general' }"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
  },
  data() {
    return {
      name: '',
      token: '',
      rol: '',
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    this.name = localStorage.getItem('name');
    this.token = localStorage.getItem('token');
    this.rol = localStorage.getItem('rol');
    if (this.name == '' || this.name == undefined) {
      window.location.href = '/#/login';
    }
    else if (this.token == '' || this.token == undefined) {
      window.location.href = '/#/lock';
    }
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
