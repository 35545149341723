<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>inventory_2</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <ValidationObserver ref="form">
                            <div class="md-layout">
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="nombre" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Nombre</label>
                                            <md-input v-model="material.nombre" type="text"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingrese Nombre de material
                                            valido</small>
                                    </ValidationProvider>
                                </div>
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="noExt" rules="required|numeric"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Stock</label>
                                            <md-input v-model="material.stock" type="number"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa stock
                                            valido</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="md-layout">
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="sueldo" rules="min:4" v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Observaciones</label>
                                            <md-input v-model="material.observaciones" type="text"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa observaciones valido</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </md-card-content>

                    <md-card-actions md-alignment="right">
                        <md-button class="md-success" @click="save">
                            <md-icon>send</md-icon>Guardar
                        </md-button>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
    name: 'MaterialForm',
    components: {

    },
    mixins: [],
    props: {
        material_edit: Object,
    },
    data() {
        return {
            material: {
                nombre: '',
                stock: 0,
                observaciones: '',
            }
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (this.material_edit != undefined) {
            this.material.nombre = this.material_edit.nombre;
            this.material.stock = this.material_edit.stock;
            this.material.observaciones = this.material_edit.observaciones;
        }
    },
    methods: {
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                if (this.material_edit != undefined) {
                    axios.post('https://api.herreriaosmar.com/api/material/' + this.material_edit.id, {
                        material: this.material,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Material editado con exito",
                            icon: "edit",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.$emit('success');
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo editar el empleado, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                } else {
                    axios.post('https://api.herreriaosmar.com/api/material', {
                        material: this.material,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Material agregado con exito",
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        window.location.href = '/#/inventario/list';
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo agregar el material, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                }
            });
        }
    }
};
</script>
<style lang='' scoped>
</style>