<template>
    <div class="form-content">
        <ValidationObserver ref="form">
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <!-- {{activecolor ? activecolor : 'green' }} -->
                            <div class="card-icon">
                                <md-icon>today</md-icon>
                            </div>
                            <h4 class="title">Fecha de instalación - <small>Aproximada</small></h4>
                        </md-card-header>

                        <md-card-content>
                            <ValidationProvider name="instalacion" rules="required" v-slot="{ passed, failed }">
                                <md-datepicker v-model="instalacion"
                                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                    <label>Selecciona Fecha</label>
                                </md-datepicker>
                                <small class="text-danger" v-show="failed">Ingrese fecha de instalacion</small>
                            </ValidationProvider>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>payments</md-icon>
                            </div>
                            <h4 class="title">Anticipo - <small>{{ antip }} %</small></h4>
                        </md-card-header>

                        <md-card-content>
                            <ValidationProvider name="anticipo" rules="required|numeric|min_value:100"
                                v-slot="{ passed, failed }">
                                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                    <label>Recibo</label>
                                    <md-input v-model.number="anticipo"></md-input>
                                </md-field>
                                <small class="text-danger" v-show="failed">Ingresa anticipo
                                    valido</small>
                            </ValidationProvider>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>shopping_cart</md-icon>
                            </div>
                            <h4 class="title">Precio de contrato - <small>restante</small></h4>
                        </md-card-header>

                        <md-card-content>
                            <md-field>
                                <label>Precio de contrato..</label>
                                <md-input :value="total >= anticipo ? total - anticipo : 100" disabled></md-input>
                            </md-field>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>request_quote</md-icon>
                            </div>
                            <h4 class="title">IVA</h4>
                        </md-card-header>

                        <md-card-content>
                            <div class="md-layout-item">
                                <ValidationProvider name="iva" rules="required" v-slot="{ passed, failed }">
                                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                        <label for="IVA">IVA</label>
                                        <md-select v-model="iva" name="IVA" id="IVA">
                                            <md-option :value="0">0% - Sin factura</md-option>
                                            <md-option :value=".11">11%</md-option>
                                            <md-option :value=".16">16%</md-option>
                                        </md-select>
                                    </md-field>
                                    <small class="text-danger" v-show="failed">Ingresa stock
                                        valido</small>
                                </ValidationProvider>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>credit_card</md-icon>
                            </div>
                            <h4 class="title">Plan de pagos<small></small></h4>
                        </md-card-header>

                        <md-card-content>
                            <div class="md-layout-item">
                                <ValidationProvider name="plan" rules="required" v-slot="{ passed, failed }">
                                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                        <label for="ppagos">Plan de pagos</label>
                                        <md-select v-model="ppagos" name="ppagos" id="ppagos">
                                            <md-option v-for="(plan, i) in planes" :key="i" :value="plan.id">{{
                                                    plan.nombre
                                            }}
                                            </md-option>
                                        </md-select>
                                    </md-field>
                                    <small class="text-danger" v-show="failed">Ingresa stock
                                        valido</small>
                                </ValidationProvider>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>credit_card</md-icon>
                            </div>
                            <h4 class="title">Cliente<small></small></h4>
                        </md-card-header>

                        <md-card-content>
                            <div class="md-layout-item">
                                <ValidationProvider name="cliente" rules="required" v-slot="{ passed, failed }">
                                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                        <label for="cliente">Clientes</label>
                                        <md-select v-model="cliente" name="cliente" id="cliente">
                                            <md-option v-for="(cliente) in clientes" :key="cliente.id"
                                                :value="cliente.id">
                                                {{
                                                        cliente.nombre + ' ' + cliente.apellido
                                                }} ( {{ cliente.calle + ' #' + cliente.num_ext }} ) </md-option>
                                        </md-select>
                                    </md-field>
                                    <small class="text-danger" v-show="failed">Ingresa un cliente</small>
                                </ValidationProvider>
                            </div>
                        </md-card-content>
                    </md-card>
                    <div class="text-right">
                        <md-button class="md-round md-info" @click.native="activemodal = true">
                            <md-icon>person_add</md-icon> Agregar Cliente
                        </md-button>
                    </div>
                </div>
            </div>

            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>vertical_shades_closed</md-icon>
                        </div>
                        <h4 class="title">Piezas</h4>
                    </md-card-header>
                    <md-card-content>
                        <md-table v-model="piezas" class="table-shopping text-center">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Diseño">
                                    <div class="img-container">
                                        <img v-if="item.diseñoPrev" :src="item.diseñoPrev" alt="Diseño" />
                                    </div>
                                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                        <label>Diseño</label>
                                        <md-file v-model="item.diseño" @change="onFileChange($event, item)"
                                            ref="file" />
                                    </md-field>
                                </md-table-cell>
                                <md-table-cell md-label="Pieza" class="td-name">
                                    <ValidationProvider :name="'piezaNombre' + item.key" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Nombre</label>
                                            <md-input v-model="item.nombre"></md-input>
                                        </md-field>
                                        <small><small class="text-danger" v-show="failed">Ingresa una pieza
                                                valida</small></small>
                                    </ValidationProvider>
                                    <br />
                                    <ValidationProvider :name="'piezaObservaciones' + item.key" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Observaciones</label>
                                            <md-textarea v-model="item.observaciones"></md-textarea>
                                        </md-field>
                                        <small><small class="text-danger" v-show="failed">Ingresa una observacion
                                                valida</small></small>
                                    </ValidationProvider>
                                </md-table-cell>
                                <md-table-cell md-label="medidas">
                                    <div v-for="(med, i) in item.medidas" :key="i">
                                        <p>{{ i }} : {{ med }}</p>
                                        <div class="md-group">
                                            <md-button class="md-round md-warning md-just-icon"
                                                @click.native="editmedida(item, i)">
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button class="md-round md-danger md-just-icon"
                                                @click.native="removemedida(item, i)">
                                                <md-icon>remove</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">

                                        <md-field>
                                            <label>Nombre</label>
                                            <md-input v-model="item.med"></md-input>
                                        </md-field>
                                        <md-field>
                                            <label>Valor</label>
                                            <md-input type="number" v-model="item.val"></md-input>
                                        </md-field>
                                        <md-button class="md-round md-info md-just-icon"
                                            @click.native="addmedida(item)">
                                            <md-icon>add</md-icon>
                                        </md-button>
                                    </div>
                                </md-table-cell>

                                <md-table-cell md-label="precio" class="td-number">
                                    <ValidationProvider :name="'piezaPrecio' + item.key" rules="required|min_value:100"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Precio</label>
                                            <span class="md-prefix">$</span>
                                            <md-input type="number" v-model="item.precio" @change="computeAmount(item)">
                                            </md-input>
                                        </md-field>
                                        <small><small class="text-danger" v-show="failed">Ingresa un precio
                                                valido</small></small>
                                    </ValidationProvider>
                                </md-table-cell>
                                <md-table-cell md-label="cantidad" class="td-number">
                                    {{ item.cantidad }}
                                    <div class="md-group">
                                        <md-button class="md-round md-success md-just-icon"
                                            @click.native="increaseQuantity(item)">
                                            <md-icon>add</md-icon>
                                        </md-button>
                                        <md-button class="md-round md-success md-just-icon"
                                            @click.native="decreaseQuantity(item)">
                                            <md-icon>remove</md-icon>
                                        </md-button>
                                    </div>
                                </md-table-cell>
                                <md-table-cell md-label="precio" class="td-number">
                                    <small>$</small>
                                    {{ item.total }}
                                </md-table-cell>
                                <md-table-cell md-label="Remove">
                                    <md-button class="md-just-icon md-round md-simple" @click.native="remove(item)">
                                        <md-icon>close</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div class="table table-stats">
                            <div class="td-price">
                                <div class="td-total">Sub Total</div>
                                <span>
                                    <small>$</small>
                                    {{ stotal }}
                                </span>
                            </div>
                            <div class="td-price" v-if="iva > 0">
                                <div class="td-total">Iva</div>
                                <span>
                                    <small>$</small> {{ ivap }}
                                </span>
                            </div>
                            <div class="td-price">
                                <div class="td-total">Total</div>
                                <span>
                                    <small>$</small>
                                    {{ total }}
                                </span>
                            </div>
                            <div class="text-right">
                                <md-button class="md-info md-round" @click="add()">
                                    Agregar Pieza
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="text-right">
                <md-button class="md-round md-success" @click="save()">
                    <md-icon>send</md-icon>Guardar
                </md-button>
            </div>
            <md-dialog :md-active.sync="activemodal">
                <md-dialog-title>Nuevo cliente</md-dialog-title>
                <ClientesForm v-on:cliente_id="nuevoCliente" />
            </md-dialog>
        </ValidationObserver>
    </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import { throws } from 'assert';
import ClientesForm from "@/components/forms/ClientesForm.vue";

export default {
    name: 'ContratosForm',
    components: {
        ClientesForm,
    },
    mixins: [],
    props: {
        contrato_edit: Object,
    },
    data() {
        return {
            cliente: null,
            clientes: [],
            instalacion: '',
            anticipo: 0,
            iva: 0,
            ivap: 0,
            planes: [],
            ppagos: 1,
            stotal: 0,
            piezas: [],
            total: 0,
            diseño: null,
            activemodal: false,
            key: 0,
        }
    },
    computed: {
        antip() {
            return (this.total > 0 ? Math.round(this.anticipo / this.total * 100) : 0);
        },
    },
    watch: {
        piezas: {
            handler(piezas) {
                this.stotal = 0;
                piezas.forEach(element => {
                    this.stotal += element.total;
                });
                this.ivap = this.iva * this.stotal;
                this.total = this.stotal + this.ivap;
            },
            deep: true
        },
        iva: {
            handler(iva) {
                this.ivap = this.iva * this.stotal;
                this.total = this.stotal + this.ivap;
            }
        }
    },
    mounted() {
        this.initialize();
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        nuevoCliente(value) {
            this.initialize();
            this.cliente = value;
            this.activemodal = false;
        },
        initialize() {
            axios.get('https://api.herreriaosmar.com/api/contrato'
            ).then(response => {
                this.planes = response.data.plan;
                this.clientes = response.data.clientes;
            }).catch(error => {

            });
            if (this.contrato_edit != undefined) {
                this.instalacion = this.contrato_edit.instalacion;
                this.anticipo = this.contrato_edit.anticipo;
                this.cliente = this.contrato_edit.cliente.id;
                this.ppagos = this.contrato_edit.plan_pagos.id;
                this.contrato_edit.piezas.forEach(this.add_edit);
                this.iva = this.contrato_edit.iva;
            }
        },
        onFileChange(e, item) {
            const file = e.target.files[0];
            item.diseñoPrev = URL.createObjectURL(file);
            let fd = new FormData();
            fd.append('image', file);
            axios.post('https://api.herreriaosmar.com/api/contrato/imagen', fd
            ).then(response => {
                item.diseño = response.data;
            }).catch(error => {
                this.$notify({
                    timeout: 2500,
                    message:
                        "No se pudo subir la imagen",
                    icon: "error",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            });
        },
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                if (this.contrato_edit != undefined) {
                    axios.post('https://api.herreriaosmar.com/api/contrato/' + this.contrato_edit.id, {
                        cliente_id: this.cliente,
                        precio_contrato: this.total,
                        anticipo: this.anticipo,
                        instalacion: this.instalacion,
                        iva: this.iva,
                        plan_pagos: this.ppagos,
                        piezas: this.piezas,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Contrato Editado con éxito, No." + response.data,
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.$emit('success');
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo editar el contrato, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                } else {
                    axios.post('https://api.herreriaosmar.com/api/contrato', {
                        cliente_id: this.cliente,
                        precio_contrato: this.total,
                        anticipo: this.anticipo,
                        instalacion: this.instalacion,
                        iva: this.iva,
                        plan_pagos: this.ppagos,
                        piezas: this.piezas,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Contrato Agregado con éxito, No." + response.data,
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        window.location.href = '/#/contratos/activos';
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo agregar el contrato, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                }
            });
        },
        increaseQuantity(item) {
            item.cantidad++;
            this.computeAmount(item);
        },
        decreaseQuantity(item) {
            if (item.cantidad > 1) {
                item.cantidad--;
                this.computeAmount(item);
            }
        },
        editmedida(item, i) {
            item.med = i;
            item.val = item.medidas[i];
        },
        removemedida(item, i) {
            this.$delete(item.medidas, i)
        },
        remove(item) {
            this.piezas.splice(this.piezas.indexOf(item), 1);
        },
        addmedida(item) {
            if (item.med != null, item.val != null) {
                item.medidas[item.med] = item.val;
                item.med = null;
                item.val = null;
            }
        },
        add_edit(item, index) {
            this.piezas.push({
                key: this.key,
                id: item.id,
                nombre: item.nombre,
                medidas: item.medidas,
                observaciones: item.observaciones,
                diseño: item.diseño,
                diseñoPrev: 'https://api.herreriaosmar.com/' + item.diseño,
                cantidad: 1,
                med: null,
                val: null,
                precio: item.precio,
                total: item.precio,
            });
            this.key++;
        },
        add() {
            this.piezas.push({
                key: this.key,
                id: this.id,
                nombre: '',
                medidas: {
                },
                observaciones: '',
                diseño: null,
                diseñoPrev: null,
                cantidad: 1,
                precio: 0,
                total: 0,
            });
            this.key++;
        },
        computeAmount(item) {
            item.total = item.precio * item.cantidad;
        },
    }
};
</script>
<style lang="scss" scoped>
.form-content {
    overflow-y: auto;
}

.text-right .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
}

.md-table .md-table-head:last-child {
    text-align: center;
}

.iva {
    width: 30%;
}

.table-stats {
    display: flex;
    align-items: center;
    text-align: right;
    flex-flow: row wrap;

    .td-price .td-total {
        display: inline-flex;
        font-weight: 500;
        font-size: 1.0625rem;
        margin-right: 50px;
    }

    &.table-striped .td-price {
        border-bottom: 0;
    }

    .td-price {
        font-size: 26px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    .td-price,
    >div {
        flex: 0 0 100%;
        padding: 12px 8px;
    }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
    text-align: center;
}
</style>