<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>attach_money</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <ValidationObserver ref="form">
                            <div class="md-layout">
                                <div class="md-layout-item md-size-100">
                                    <ValidationProvider name="Concepto" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Concepto..</label>
                                            <md-input v-model="concepto"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa un concepto valida</small>
                                    </ValidationProvider>
                                </div>
                                <div class="md-layout-item md-size-100">
                                    <ValidationProvider name="cantidad" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Monto..</label>
                                            <md-input v-model="cantidad"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa un monto valido</small>
                                    </ValidationProvider>
                                </div>
                                <div class="md-layout-item md-size-100">
                                    <ValidationProvider name="tipo" rules="required" v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label for="tipo">Tipo</label>
                                            <md-select v-model="tipo" name="tipo" id="tipo">
                                                <md-option :value="null">Selecciona tipo de movimiento</md-option>
                                                <md-option value="entrada">Entrada</md-option>
                                                <md-option value="salida">Salida</md-option>
                                            </md-select>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingrese fecha de instalacion</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </md-card-content>
                    <md-card-actions md-alignment="right">
                        <md-button class="md-success" @click="save">
                            <md-icon>send</md-icon>Guardar
                        </md-button>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
    name: 'TransaccionesForm',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            concepto: null,
            tipo: null,
            cantidad: null,
            autorizo: 1,
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                axios.post('https://api.herreriaosmar.com/api/transaccion', {
                    concepto: this.concepto,
                    tipo: this.tipo,
                    cantidad: this.cantidad,
                    autorizo: this.autorizo,
                }).then(response => {
                    this.$notify({
                        timeout: 2500,
                        message:
                            "Moviemiento Agregado con exito.",
                        icon: "paid",
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    this.$emit('created');
                }).catch(error => {
                    this.$notify({
                        timeout: 2500,
                        message:
                            "No se pudo agregar el movimiento, vuelve a intentar",
                        icon: "error",
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                });
            });
        },
    }
};
</script>
<style lang='' scoped>
</style>