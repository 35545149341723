<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://creative-tim.com/presentation">
              Soporte
            </a>
          </li>
          <li>
            <a href="https://www.creative-tim.com/license">Quienes Somos</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
