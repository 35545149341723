<template>
  <div class="form-content">
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>build</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <md-table v-model="piezas">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Pieza">
                  {{ item.nombre }} <br>
                  <small>{{item.observaciones}}</small>
                </md-table-cell>
                <md-table-cell md-label="Material">
                  <div v-for="(mat, i) in item.asignacion" :key="i">
                    -{{materiales[i-1].nombre}} : {{mat}}
                    <md-button class="md-just-icon md-round md-simple" @click.native="remove(item, i)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </md-table-cell>
                <md-table-cell md-label="">
                  <div class="md-layout">
                    <md-field>
                      <label for="material">Material</label>
                      <md-select v-model="item.material" name="material" id="material">
                        <md-option value="0" class="md-disabled" disabled>Selecciona empleado</md-option>
                        <md-option :value="m.id" v-for="m in materiales" :key="m.id">
                          {{m.nombre}}
                        </md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label>Cantidad</label>
                      <md-input v-model="item.cantidad"></md-input>
                    </md-field>
                    <md-button class="md-info" @click="add(item)">
                      <md-icon>add</md-icon>
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
        <div class="text-right">
          <md-button class="md-round md-success" @click="save()">
            <md-icon>send</md-icon>Guardar
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { add } from '@tweenjs/tween.js';
import { validate } from 'json-schema';
import { integer } from 'vee-validate/dist/rules';

const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
  name: 'asignmaterial_form',
  components: {

  },
  mixins: [],
  props: {
    contrato_id: integer,
  },
  data() {
    return {
      piezas: [],
      materiales: [],
      asignacion: {},
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    axios.get('https://api.herreriaosmar.com/api/asignacion/material/' + this.contrato_id
    ).then(response => {
      this.materiales = response.data.material;
      response.data.piezas.forEach(this.initPiezas);
    }).catch(error => {

    });
  },
  methods: {
    initPiezas(item, index) {
      this.piezas.push({
        id: item.id,
        nombre: item.nombre,
        asignacion: {},
        observaciones: item.observaciones,
        material: 0,
        cantidad: 0,
      });
      item.material_pieza.forEach(this.initasignacion);
    },
    initasignacion(item, index){
      this.piezas.at(-1).asignacion[item.material_id] = item.numero_piezas;
    },
    add(value) {
      value.asignacion[value.material] = value.cantidad;
      value.material = 0;
      value.cantidad = 0;
    },
    remove(item, i) {
      this.$delete(item.asignacion, i)
    },
    save() {
      axios.post('https://api.herreriaosmar.com/api/asignacion/material', {
        piezas: this.piezas,
      }).then(response => {
        this.$notify({
          timeout: 2500,
          message:
            "Material Asignado correctamente",
          icon: "archive",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
        });
        this.$emit('success');
      }).catch(error => {
        this.$notify({
          timeout: 2500,
          message:
            "No se pudo realizar la asignación, vuelve a intentar",
          icon: "error",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
        });
      });
    },
  }
};
</script>
<style lang='scss' scoped>
.form-content {
  overflow-y: auto;
}
</style>