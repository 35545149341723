<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>person_add</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <ValidationObserver ref="form">
                            <form>
                                <div class="md-layout">
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="nombre" rules="required|min:4"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Nombre</label>
                                                <md-input v-model="cliente.nombre" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingrese Nombre valido</small>
                                        </ValidationProvider>
                                    </div>

                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="apellido" rules="required|min:4"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Apellido</label>
                                                <md-input v-model="cliente.apellido" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingrese Apellido valido</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!--  :rules="{ required: true, regex: /^\+[1-9]{2} \([0-9]{3}\) [0-9]{3}(\-[0-9]{2}){2}$/ }" -->
                                <div class="md-layout">
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="telefono"
                                            rules="required|numeric|min:10 "
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Telefono</label>
                                                <md-input v-model="cliente.telefono" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingrese Numero valido</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="pais" rules="required" v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label for="pais">Pais</label>
                                                <md-select v-model="cliente.pais" name="pais" id="pais">
                                                    <md-option value="null" class="md-disabled" disabled>Selecciona pais
                                                    </md-option>
                                                    <md-option :value="pais.id" v-for="pais in paises" :key="pais.id">
                                                        {{ pais.nombre }}
                                                    </md-option>
                                                </md-select>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Selecciona un pais</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="estado" rules="required" v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label for="estado">Estado</label>
                                                <md-select v-model="cliente.estado" name="estado" id="estado">
                                                    <md-option value="null" class="md-disabled" disabled>Selecciona
                                                        estado
                                                    </md-option>
                                                    <md-option :value="estado.id" v-for="estado in estados"
                                                        :key="estado.id">
                                                        {{ estado.nombre }}
                                                    </md-option>
                                                </md-select>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Selecciona un estado</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="ciudad" rules="required" v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label for="ciudad">Ciudad</label>
                                                <md-select v-model="cliente.ciudad" name="ciudad" id="ciudad">
                                                    <md-option value="null" class="md-disabled" disabled>Selecciona pais
                                                    </md-option>
                                                    <md-option :value="ciudad.id" v-for="ciudad in ciudades"
                                                        :key="ciudad.id">
                                                        {{ ciudad.nombre }}
                                                    </md-option>
                                                </md-select>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Selecciona una ciudad</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="fraccionamiento" rules="required|min:4"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Fraccionamiento</label>
                                                <md-input v-model="cliente.fracc" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingresa un fraccionamiento
                                                valido</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="calle" rules="required|min:4"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Calle</label>
                                                <md-input v-model="cliente.calle" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingresa una calle valida</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="noExt" rules="required|min:3|numeric"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Numero exterior</label>
                                                <md-input v-model="cliente.num_ext" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingresa un numero exterior
                                                valido</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="nInt" rules="numeric" v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Numero interior</label>
                                                <md-input v-model="cliente.num_int" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingresa un numero interior
                                                valido</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                        <ValidationProvider name="cp" rules="required|min:3|numeric"
                                            v-slot="{ passed, failed }">
                                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                                <label>Codigo Postal</label>
                                                <md-input v-model="cliente.cp" type="text"></md-input>
                                            </md-field>
                                            <small class="text-danger" v-show="failed">Ingresa codigo postal
                                                valido</small>
                                        </ValidationProvider>
                                    </div>
                                    <div class="md-layout-item md-small-size-100">
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </md-card-content>

                    <md-card-actions md-alignment="right">
                        <md-button class="md-success" @click="save">
                            <md-icon>send</md-icon>Guardar
                        </md-button>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
import objectAssign from 'object-assign';
import { required, numeric, regex, confirmed, min } from "vee-validate/dist/rules";


const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
    name: 'ClientesForm',
    components: {

    },
    mixins: [],
    props: {
        cliente_edit: Object,
    },
    data() {
        return {
            cliente: {
                nombre: '',
                apellido: '',
                telefono: '',
                pais: null,
                estado: null,
                ciudad: null,
                fracc: '',
                calle: '',
                num_ext: '',
                num_int: '',
                cp: '',

            },
            init: false,
            paises: [],
            estados: [],
            ciudades: [],
        }
    },
    computed: {

    },
    watch: {
        'cliente.pais': function () {
            axios.get('https://api.herreriaosmar.com/api/estados/' + this.cliente.pais
            ).then(response => {
                this.cliente.estado = 0;
                this.cliente.ciudad = 0;
                this.estados = response.data.estados;
            }).catch(error => {

            });
        },
        'cliente.estado': function () {
            axios.get('https://api.herreriaosmar.com/api/ciudades/' + this.cliente.estado
            ).then(response => {
                this.ciudades = response.data.ciudades;
                this.cliente.ciudad = 0;
            }).catch(error => {

            });
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            axios.get('https://api.herreriaosmar.com/api/cliente'
            ).then(response => {
                this.paises = response.data.paises;
            }).catch(error => {

            });
            if (this.cliente_edit != undefined) {
                this.cliente.nombre = this.cliente_edit.nombre;
                this.cliente.apellido = this.cliente_edit.apellido;
                this.cliente.telefono = this.cliente_edit.telefono;
                this.cliente.pais = this.cliente_edit.pais;
                this.cliente.estado = this.cliente_edit.estado;
                this.cliente.ciudad = this.cliente_edit.ciudad;
                this.cliente.fracc = this.cliente_edit.fracc;
                this.cliente.calle = this.cliente_edit.calle;
                this.cliente.num_int = this.cliente_edit.num_int;
                this.cliente.num_ext = this.cliente_edit.num_ext;
                this.cliente.cp = this.cliente_edit.cp;
            }
        },
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                if (this.cliente_edit != undefined) {
                    axios.post('https://api.herreriaosmar.com/api/cliente/' + this.cliente_edit.id, {
                        cliente: this.cliente,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Cliente agregado con exito",
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.$emit('success');
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo agregar el cliente, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                } else {
                    axios.post('https://api.herreriaosmar.com/api/cliente', {
                        cliente: this.cliente,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Cliente agregado con exito",
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.$emit('cliente_id', response.data.id);
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo agregar el cliente, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                }
            });
        }
    }
};
</script>
<style lang='' scoped>
</style>