<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>admin_panel_settings</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <ValidationObserver ref="form">
                            <div class="md-layout">
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="nombre" rules="required|min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Nombre</label>
                                            <md-input v-model="empleado.nombre" type="text"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingrese Nombre valido</small>
                                    </ValidationProvider>
                                </div>
                                <!-- <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>Apellido</label>
                                    <md-input v-model="empleado.apellido" type="text"></md-input>
                                </md-field>
                            </div> -->
                            </div>
                            <div class="md-layout">
                                <div class="md-layout-item md-small-size-100">

                                    <ValidationProvider name="Correo" rules="required|email"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Correo</label>
                                            <md-input v-model="empleado.correo" type="text"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingrese correo valido</small>
                                    </ValidationProvider>
                                </div>
                                <!-- <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>contraseña</label>
                                    <md-input v-model="empleado.contraseña" type="password"></md-input>
                                </md-field>
                            </div> -->
                            </div>
                            <div class="md-layout">
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="rol" rules="required" v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label for="rol">Rol</label>
                                            <md-select v-model="empleado.rol" name="rol" id="rol">
                                                <md-option :value="null" class="md-disabled" disabled>Selecciona rol
                                                </md-option>
                                                <md-option value="Administrador">Administrador</md-option>
                                                <md-option value="Gerente">Gerente</md-option>
                                                <md-option value="Herrero">Herrero</md-option>
                                            </md-select>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Selecciona un rol</small>
                                    </ValidationProvider>
                                </div>
                                <div class="md-layout-item md-small-size-100" v-if="empleado.rol == 'Herrero'">
                                    <ValidationProvider name="sueldo" rules="required|numeric|min_value:1"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Sueldo Semanal</label>
                                            <md-input v-model="empleado.sueldo" type="number"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa sueldo valido</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="md-layout" v-if="empleado.rol == 'Herrero'">
                                <div class="md-layout-item md-small-size-100">
                                    <ValidationProvider name="sueldo" rules="min:4"
                                        v-slot="{ passed, failed }">
                                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                                            <label>Observaciones</label>
                                            <md-input v-model="empleado.observaciones" type="text"></md-input>
                                        </md-field>
                                        <small class="text-danger" v-show="failed">Ingresa observaciones validas</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </md-card-content>

                    <md-card-actions md-alignment="right">
                        <md-button class="md-success" @click="save">
                            <md-icon>send</md-icon>Guardar
                        </md-button>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
import { required, numeric, regex, confirmed, min } from "vee-validate/dist/rules";
axios.defaults.withCredentials = true;
export default {
    name: 'EmpleadosForm',
    components: {

    },
    mixins: [],
    props: {
        empleado_edit: Object,
    },
    data() {
        return {
            empleado: {
                nombre: '',
                apellido: '',
                correo: '',
                rol: null,
                // contraseña: '',
                sueldo: 0,
                observaciones: '',
            },
        }
    },
    computed: {

    },
    watch: {
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            if (this.empleado_edit != undefined) {
                this.empleado.nombre = this.empleado_edit.name;
                this.empleado.correo = this.empleado_edit.email;
                this.empleado.rol = this.empleado_edit.tipo;
                if (this.empleado.rol == 'Herrero') {
                    this.empleado.sueldo = this.empleado_edit.empleado.sueldo_semanal;
                    this.empleado.observaciones = this.empleado_edit.empleado.observaciones;
                }
            }
        },
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                if (this.empleado_edit != undefined) {
                    axios.post('https://api.herreriaosmar.com/api/empleado/' + this.empleado_edit.id, {
                        empleado: this.empleado,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Empleado editado con exito",
                            icon: "edit",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.$emit('success');
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo editar el empleado, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                } else {
                    axios.post('https://api.herreriaosmar.com/api/empleado', {
                        empleado: this.empleado,
                    }).then(response => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "Empleado agregado con exito, contraseña: " + response.data,
                            icon: "archive",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        window.location.href = '/#/empleados/list';
                    }).catch(error => {
                        this.$notify({
                            timeout: 2500,
                            message:
                                "No se pudo agregar el empleado, vuelve a intentar",
                            icon: "error",
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                    });
                }
            });
        }
    }
};
</script>
<style lang='' scoped>
</style>