<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>person</md-icon>
            </div>
            <h4 class="title">Clientes</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="clientes">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Numero de cliente">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Cliente">
                  {{ item.nombre}} {{ item.apellido }}
                </md-table-cell>
                <md-table-cell md-label="Direccion">
                  {{item.fracc }} <br>
                  <small>{{ item.calle }} #{{ item.num_ext }} {{ item.num_int }} </small>
                </md-table-cell>
                <md-table-cell md-label="Acciones" :class="getAlignClasses(item)">
                  <!-- <md-button class="md-just-icon md-info md-round">
                    <md-icon>location_on</md-icon>
                  </md-button> -->
                  <md-button class="md-just-icon md-warning md-round" @click.native="edit(item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <md-dialog :md-active.sync="modalcliente">
      <md-dialog-title>Editar cliente</md-dialog-title>
      <ClientesForm :cliente_edit="cliente_edit" v-on:success="edited"/>
    </md-dialog>
  </div>
</template>
<script>
import ClientesForm from '../../components/forms/ClientesForm.vue';
const axios = require('axios').default;
axios.defaults.withCredentials = true;
export default {
  name: 'Clientes_list',
  components: {
    ClientesForm
},
  mixins: [],
  props: {

  },
  data() {
    return {
      clientes: [],
      cliente_edit: null,
      modalcliente: false,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    axios.get('https://api.herreriaosmar.com/api/clientes'
    ).then(response => {
      this.clientes = response.data.clientes;
    }).catch(error => {

    });
  },
  methods: {
    edit(item){
      this.cliente_edit = item;
      this.modalcliente = true;      
    },
    edited(){
      this.modalcliente = false;
    },
    getAlignClasses: ({ id }) => ({
      "text-right": id,
    }),
  },
};
</script>
<style lang='' scoped>
</style>