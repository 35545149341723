<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">Contratos</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="contratos">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Numero de contrato">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Cliente">
                  {{ item.cliente.nombre }} {{ item.cliente.apellido }} <br>
                  <small>
                    {{ item.cliente.calle }} #{{ item.cliente.num_ext }} {{ item.cliente.num_int }} {{
                        item.cliente.fracc
                    }}
                  </small>
                </md-table-cell>
                <md-table-cell md-label="Descripcion">
                  <span v-for="pieza in item.piezas" :key="pieza.id">
                    {{ pieza.nombre }} - <div v-if="pieza.finalizado != null" class="text-success">Finalizada</div>
                    <div v-else class="text-warning">En espera</div><br>
                    <!-- <span v-if="pieza.id != item.piezas[item.piezas.length - 1].id">,</span> -->
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Costo">
                  $ {{ item.precio_contrato }}
                </md-table-cell>
                <md-table-cell md-label="Fecha de instalacion">
                  {{ item.instalacion }}
                </md-table-cell>
                <md-table-cell md-label="Plan de pagos">
                  {{ item.plan_pagos.nombre }}
                </md-table-cell>
                <md-table-cell md-label="Actions" :class="getAlignClasses(item)">
                  <md-button class="md-just-icon md-info md-round"
                    :href="'https://api.herreriaosmar.com/api/contrato/' + item.token" target="_blank">
                    <md-icon>file_download</md-icon>
                  </md-button>
                  <md-button class="md-just-icon md-warning md-round" @click.native="edit(item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-just-icon md-success md-round" @click.native="asignar_material(item.id)">
                    <md-icon>build</md-icon>
                  </md-button>
                  <md-button class="md-just-icon md-primary md-round" @click.native="asignar_empleado(item.id)">
                    <md-icon>badge</md-icon>
                  </md-button>
                  <md-button v-if="rol == 'Gerente'"  class="md-just-icon md-danger md-round" @click.native="finalizar(item.id)">
                    <md-icon>check</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <md-dialog :md-active.sync="modalcontrato">
      <md-dialog-title>Editar Contrato</md-dialog-title>
      <ContratosForm :contrato_edit="contrato_edit" v-on:success="reload" />
    </md-dialog>
    <!-- <md-dialog :md-active.sync="modaldescarga">
      <md-dialog-title>Descargar contrato</md-dialog-title>
    </md-dialog> -->
    <md-dialog :md-active.sync="modalmaterial">
      <md-dialog-title>Asignar material</md-dialog-title>
      <AsigMaterialForm :contrato_id="contrato_id" v-on:success="reload" />
    </md-dialog>
    <md-dialog :md-active.sync="modalasignacion">
      <md-dialog-title>Asignar Piezas</md-dialog-title>
      <AsignacionForm :contrato_id="contrato_id" v-on:success="reload" />
    </md-dialog>
  </div>
</template>
<script>
import ContratosForm from '../../components/forms/ContratosForm.vue';
import AsignacionForm from '../../components/forms/AsignacionForm.vue';
import AsigMaterialForm from '../../components/forms/AsigMaterialForm.vue';
const axios = require('axios').default;
axios.defaults.withCredentials = true;
import Swal from "sweetalert2";
export default {
  name: 'contratos_activos',
  components: {
    ContratosForm,
    AsignacionForm,
    AsigMaterialForm
  },
  mixins: [],
  props: {

  },
  data() {
    return {
      contratos: [],
      contrato_id: 0,
      contrato_edit: null,
      modalcontrato: false,
      modalmaterial: false,
      modalasignacion: false,
      rol: '',
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.initialize();
    this.rol = localStorage.getItem('rol');
  },
  methods: {
    initialize() {
      axios.get('https://api.herreriaosmar.com/api/contratos'
      ).then(response => {
        this.contratos = response.data;
      }).catch(error => {

      });
    },
    reload() {
      this.modalmaterial = false;
      this.modalasignacion = false;
      this.modalcontrato = false;
      this.initialize();
    },
    edit(item) {
      this.contrato_edit = item;
      this.modalcontrato = true;
    },
    asignar_empleado(id) {
      this.contrato_id = id;
      this.modalasignacion = true;
    },
    finalizar(id) {
      Swal.fire({
        title: "Estas seguro que lo quieres marcar como instalado",
        text: `No podras revertir esto`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.delete('https://api.herreriaosmar.com/api/contrato/finalizar/' + id
          ).then(response => {
            
            this.$notify({
              timeout: 2500,
              message:
                response.data == '' ? 'Contrato finalizado con exito' : response.data,
              icon: "check_circle",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: response.data == '' ? 'success' : 'danger',
            });
          }).catch(error => {
            this.$notify({
              timeout: 2500,
              message:
                "No se ha podido finalizar el contrato, vuelva a intentar",
              icon: "error",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger',
            });
          });
        }
      });
      initialize();
    },
    asignar_material(id) {
      this.contrato_id = id;
      this.modalmaterial = true;
    },
    getAlignClasses: ({ id }) => ({
      "text-right": id,
    }),
  },
};
</script>
<style lang='' scoped>
</style>